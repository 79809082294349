import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PartnersPage = ({data}) => (
  <Layout className="w-full lg:w-3/4 mx-auto px-8 lg:pr-16">
    <SEO title="Met wie werken wij samen" />
    <h1>Met wie werken wij samen?</h1>
    <h2>Partners</h2>
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
      {
      data.partners.nodes.map(partner => {
      return <a href={partner.url} alt={partner.name}><Img objectFit="contain" className="w-full" fluid={partner.image.childImageSharp.fluid} /></a>
      })
      }
    </div>

    <h2>Merken</h2>
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 text-center">
      {
      data.brands.nodes.map(brand => {
      return <a href={brand.url} alt={brand.name}><Img objectFit="contain" className="w-full" fluid={brand.image.childImageSharp.fluid} /></a>
      })
      }
    </div>
    
  </Layout>
)

export default PartnersPage

export const query =  graphql`
{
  partners: allPartnersYaml {
    nodes {
      name
      url
      image {
        childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  }
  brands: allBrandsYaml {
    nodes {
      name
      url
      image {
        childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
  }
}

`